import React, { Component } from 'react';
import './App.css';
import logo from './images/logo.png';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img className="logo" src={logo} alt="Lok" />
          <p>Get Controll on your money</p>
        </header>
      </div>
    );
  }
}

export default App;
